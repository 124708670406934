<template>
  <div class="apply">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信贷产品申请</div>
    <div class="header">
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon1.png" alt="">
            </div>
            <div class="p">企业信贷需求信息</div>
        </div>
        <div class="step">
            <div class="icon activebg">
                <img src="../../assets/img/applyicon2.png" alt="">
            </div>
            <div class="p active">企业基本信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon3.png" alt="">
            </div>
            <div class="p">企业注册登记信息</div>
        </div>
        <div class="step">
            <div class="icon" style="padding-top: 1.5vw;">
                <img src="../../assets/img/applyicon4.png" alt="">
            </div>
            <div class="p">企业经营信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon5.png" alt="">
            </div>
            <div class="p">完成</div>
        </div>
    </div>
    <p class="title">企业基本信息</p>
    <div class="card">
        <div class="catalog">
            <p>企业名称</p>
            <input type="text" v-model="requestData.enterName" maxlength="100">
        </div>
        <div class="catalog">
            <p>企业性质</p>
            <div class="select">
           <select v-model="requestData.enterProperty">
                <option value="0">企业法人</option>
                <option value="1">企业非法人</option>
                <option value="2">事业单位</option>
                <option value="3">个体户</option>
                <option value="4">其他</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>所属省</p>
            <div class="select">
            <select v-model="requestData.provinceCode" disabled>
                <option value="450000">广西</option>
            </select>
            </div>
        </div>   
        <div class="catalog">
            <p>所属市</p>
            <div class="select">
            <select v-model="requestData.cityCode">
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>所属区<span class="key">*</span></p>
            <div class="select">
            <select v-model="requestData.districtCode">
                <option value="">请选择</option>
                <option v-for="item in districtList" :key="item.cityCode" :value="item.cityCode">{{item.cityName}}</option>
            </select>
            </div>
        </div>   
        <div class="catalog">
            <p>成立日期<span class="key">*</span></p>
            <!-- <input type="text" v-model="requestData.foundDate" disabled> -->
            <div class="select">
            <input type="date" v-model="requestData.foundDate">
            </div>
        </div>
        <div class="catalog">
            <p>人数<span class="key">*</span></p>
            <input type="number" v-model="requestData.peopleCnt" oninput="if(value.length>10) value=value.slice(0,10)">
        </div>
        <div class="catalog">
            <p>规模<span class="key">*</span></p>
            <div class="select">
            <select v-model="requestData.scale">
                <option value="">请选择</option>
                <option value="0">微型</option>
                <option value="1">小型</option>
                <option value="2">中型</option>
                <option value="3">大型</option>
            </select>
            </div>
        </div>
        <div class="catalog">
            <p>所属行业<span class="key">*</span></p>
            <div class="select">
            <select v-model="requestData.involveIndustry">
                <option value="">请选择</option>
                <option value="A">农、林、牧、渔业</option>
                <option value="B">采矿业</option>
                <option value="C">制造业</option>
                <option value="D">电力、燃气及水的生产供应业</option>
                <option value="E">建筑业</option>
                <option value="F">交通运输、仓储环境和邮政业</option>
                <option value="G">信息传输、计算机服务业</option>
                <option value="H">批发和零售业</option>
                <option value="I">住宿和餐饮业</option>
                <option value="J">金融业</option>
                <option value="K">房地产业</option>
                <option value="L">租赁和商务服务业</option>
                <option value="M">科学研究、技术服务和地质勘查业</option>
                <option value="N">水利、环境和公共设施管理</option>
                <option value="O">居民服务和其他服务业</option>
                <option value="P">教育业</option>
                <option value="Q">卫生、社会保障和社会福利业</option>
                <option value="R">文化、教育和娱乐业</option>
                <option value="S">公共管理和社会组织</option>
                <option value="T">其他行业</option>
           </select>
            </div>
        </div>   
        <div class="catalog">
            <p style="height: 15vw">主营业务<span class="key">*</span></p>
            <textarea v-model="requestData.mainBusiness" maxlength="10"></textarea>
        </div>
        <div class="catalog">
            <p>法定代表人<span class="key">*</span></p>
            <input type="text" v-model="requestData.legalName" maxlength="50">
        </div>
        <div class="catalog">
            <p>法定代表人证件类型<span class="key">*</span></p>
            <div class="select">
            <select v-model="requestData.legalCertiType">
                <option value="0">身份证</option>
                <option value="1">护照</option>
                <option value="2">港澳台居民通行证</option>
                <option value="3">港澳台居民居住证</option>
                <option value="4">其他合法身份证件</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>法定代表人证件号码<span class="key">*</span></p>
            <input type="text" v-model="requestData.legalCertiNum" maxlength="30">
        </div>
         <div class="catalog">
            <p>总经理</p>
            <input type="text" v-model="requestData.generalManagerName" maxlength="50" placeholder="输入总经理姓名">
        </div>   
        <div class="catalog">
            <p>总经理电话号码</p>
            <input type="number" v-model="requestData.generalManagerTelNum" oninput="if(value.length>11) value=value.slice(0,11)" placeholder="输入总经理电话号码">
        </div>
        <div class="catalog">
            <p>财务总监</p>
            <input type="text" v-model="requestData.financialOfficerName" maxlength="50" placeholder="输入财务总监姓名">
        </div>   
        <div class="catalog">
            <p>财务总监电话号码</p>
            <input type="number" v-model="requestData.financialOfficerTelNum" oninput="if(value.length>11) value=value.slice(0,11)" placeholder="输入财务总监电话号码">
        </div>
        <div class="catalog">
            <p>联系人姓名<span class="key">*</span></p>
            <input type="text" v-model="requestData.contactsName" maxlength="50">
        </div>
        <div class="catalog">
            <p>联系人手机号码<span class="key">*</span></p>
            <input type="number" v-model="requestData.contactsPhone" oninput="if(value.length>11) value=value.slice(0,11)">
        </div>
    </div>
    <div style="text-align: center;padding: 4vw 0;">
        <div class="next" @click="goBack">上一步</div>
        <div class="next" @click="nextStep()">下一步</div>
    </div>
    
  </div>
</template>
<script>
import { Toast } from 'vant';
import { ref,reactive,toRaw,toRefs,watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { extendJson,apiGetDistrict } from '../../api/axios';
export default {
   setup() {
    const checked = ref('1');
    let router = useRouter();
    // let proId=router.currentRoute.value.query.proIds;
    let store = useStore();
    let requireInfo;
    const requestData = ref({
        enterName: '',
        enterProperty: '0',
        provinceCode: '450000',
        provinceName: '广西',
        cityCode: '450100',
        districtCode: '',
        foundDate: '',
        peopleCnt: '',
        scale: '0',
        involveIndustry: 'A',
        mainBusiness: '',
        legalName: '',
        legalCertiType: '',
        legalCertiNum: '',
        generalManagerName: '',
        generalManagerTelNum: '',
        financialOfficerName: '',
        financialOfficerTelNum: '',
        contactsName: '',
        contactsPhone:'',
    });
    const districtList = ref([]);
    if(store.state.requireInfo) {
        requireInfo = store.state.requireInfo.enterBaseInfo;
        requestData.value = extendJson(toRaw(requestData.value),requireInfo);
        requestData.value = extendJson(toRaw(requestData.value),store.state.saveFrontRequirement);
    }else {
        router.push({ name:'home',replace:true});
    }

    //获取区县
    function getDistrict() {
        apiGetDistrict({cityCode: requestData.value.cityCode}).then(res => {
            // 获取数据成功后的其他操作
            console.log(res,'获取的数据')
            if(res.code === '1'){
                districtList.value = res.info.districtList;  
            }
            else {
                Toast(res.msg);
            }           
        })
    };
    getDistrict();
    //监听所属市变化
    watch(()=> requestData.value.cityCode, (new1,old1) => {
            getDistrict();
    })

     const nextStep= () => {
        //  if(state.requestData.bankCode.indexOf('')=== 0) {
        //     Toast('请先选择贷款银行！');
        //     return;
        // }
        // state.requestData.bankCode.forEach((item)=>{
        //     if(!item) {
        //         Toast('请先选择贷款银行！')
        //         return false;
        //     }
        // })
        if(!requestData.value.districtCode) {
            Toast('请先选择所属区！')
            return;
        }
        if(!requestData.value.foundDate) {
            Toast('请先选择成立时间！')
            return;
        }
        if(!requestData.value.peopleCnt) {
            Toast('请先填写公司人数！')
            return;
        }
        if(!requestData.value.mainBusiness) {
            Toast('请先填写公司主营业务！')
            return;
        }
        if(!requestData.value.legalName) {
            Toast('请先填写公司法人姓名！')
            return;
        }
        if(!requestData.value.legalCertiNum) {
            Toast('请先填写公司法人证件号码！')
            return;
        }
        if(!requestData.value.contactsName) {
            Toast('请先填写公司联系人姓名！')
            return;
        }
        if(!requestData.value.contactsPhone) {
            Toast('请先填写公司法联系人手机号码！')
            return;
        }
   

        const sum=extendJson(toRaw(store.state.saveFrontRequirement),toRaw(requestData.value));
        store.dispatch('setRequestData',sum);
        // console.log(sum,'applyB');
        router.push({ name:'applyC',replace:true})
    };
    const goBack=() => {
        const sum=extendJson(toRaw(store.state.saveFrontRequirement),toRaw(requestData.value));
        // console.log(sum,333)                    
        store.dispatch('setRequestData',sum);
        if(store.state.requireInfo.routerName === 'apply') {
            router.push({
                name: 'apply',
                replace:true,
                query: {proIds: requestData.value.productIds}
            });
        }else {
            router.push({
                name: 'apply2',
                replace:true,
                query: {reqFlag: store.state.requireInfo.reqFlag}
            });
        }
        
    };
    return {
        requireInfo,
        districtList,
        checked,
        requestData,
        nextStep,
        goBack
    };
  },
//   methods:{
//     goBack(){
//         this.$router.go(-1);
//     },

//   }
}
</script>
<style lang="less" scoped>
.apply {
    padding-top: 12.5vw;
.header {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw;
    display: flex;
    justify-content: space-between;
    .step {
        width: 13vw;
        .icon {
            width: 100%;
            height: 13vw;
            background-color: #cccccc;
            border-radius: 50%;
            text-align: center;
            line-height: 13vw;
            box-sizing: border-box;
            img {
                transform: scale(0.5);
            }
        }
        .p {
            color: #666666;
            font-size: 3.1vw;
            text-align: center;
            margin-top: 2vw;
        }
        .activebg {
            background-color: #ff9900;
        }
        .active {
            color: #ff9900;
        }
    }
}
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    
    .card {
        padding: 2vw 4vw;
        .headline {
            background-color: #fdf8ed;
            color: #ff9900;
            font-size: 4vw;
            padding: 2vw;
            border-radius: 1.5vw;
        }
        .catalog {
            display: flex;
            align-items: center;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
                // padding: 3vw 0;
            }
            select,input,textarea {
                background-color: #f4f8fe;
                border: 0;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
                margin-left: 2vw;
            }
            textarea {
                height: 15vw;
                resize: none;
            }
        }
        
    }
    .next {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4vw;
        padding: 1.5vw 5vw;
        border-radius: 5vw;
        margin-left: 4vw;
    }
    
}
</style>
